<template>
  <div class="slider pos-rel">
    <div class="swiper-container horizontals"
         style="width:100%">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher1.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher2.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher3.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher4.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher5.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher6.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher7.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher8.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher9.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher10.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher11.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher12.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher13.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher14.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher15.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher16.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher17.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher18.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher19.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher20.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher21.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher22.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher23.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher24.png"
               alt="">
        </div>
        <div class="swiper-slide">
          <img src="../../../assets/img/teacher/teacher25.png"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

export default {
  name: 'Slider',
  data() {
    return {}
  },
  mounted() {
    new Swiper('.horizontals', {
      loop: true, //无缝轮播
      loopAdditionalSlides: 3,
      direction: 'horizontal',
      mousewheelControl: false, //开启鼠标滚轮控制
      // scrollbar: '.swiper-scrollbar', // 如果需要滚动条
      centeredSlides: true, //活动块会居中，而非默认状态下的居左..
      slidesPerColumn: 1, //多行布局里面每列的slide数量。
      slidesPerColumnFill: 'row', //多行布局中以什么形式填充：column
      slidesPerView: 'auto', //slider容器能够同时显示的slides数量(carousel模式)
      loopedSlides: 12,
      autoplay: 2000, //如果需要自动切换海报
      grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状
    })
  },
}
</script>

<style lang="scss" scoped>
.slider {
  margin-top: 30px;
}
.swiper-container {
  height: auto;
  width: 100%;
}
.swiper-wrapper .swiper-slide {
  width: 350px !important;
  height: 440px;
  img {
    width: 100%;
    height: 100%;
  }
}
.swiper-button-next {
  right: 40px;
}
.swiper-button-prev {
  left: 40px;
}
</style>