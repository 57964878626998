<template>
  <Layout class="pos-rel">
    <div class="banner"
         v-if="bannerBg"
         :style="`background-image:url(${bannerBg})`">
    </div>
    <div class="trans-box"
         :style="{marginTop:`${translateHeight}px`}">

      <div class="international rule-model-bg">
        <h1 class="tx-c">大师与教研团队</h1>
        <div class="rule-width flex flex-sb">
          <div class="tab"
               @click="activeTab = 1">
            <h2>复合型国际钢琴大师</h2>
            <div class="bottom-wire"
                 v-if="activeTab == 1"></div>
          </div>
          <div class="tab"
               style="margin-right:20px"
               @click="activeTab = 2">
            <h2>国内钢琴教研团队</h2>
            <div class="bottom-wire"
                 v-if="activeTab == 2"></div>
          </div>
          <div class="tab"
               @click="activeTab = 3">
            <h2>一线院校钢琴老师</h2>
            <div class="bottom-wire"
                 v-if="activeTab == 3"></div>
          </div>
        </div>

        <div class="one rule-width"
             v-show="activeTab == 1">
          <img style="margin-left:-20px"
               src="@/assets/img/teacher1.png"
               alt="">
          <img style="margin-left:420px;margin-top:-260px"
               src="@/assets/img/teacher2.png"
               alt="">
        </div>

        <div class="two rule-width"
             v-show="activeTab == 2">
          <img style="margin-left:0px"
               src="@/assets/img/teacher3.png"
               alt="">
          <img style="margin-left:430px;margin-top:-300px"
               src="@/assets/img/teacher4.png"
               alt="">
        </div>

        <div class="three">
          <LoopImg v-if="activeTab == 3"></LoopImg>
        </div>

        <!-- <div style="width:800px"
           class="rule-width flex flex-sb"
           ref="teacherInter">
        <div class="l">
          <div class="img animated fadeInLeft"
               v-show="teacherInter">
            <img src="@/assets/img/teacher4.png"
                 class="trans"
                 alt="">
          </div>
          <div class="letter animated fadeInLeft delay-6"
               v-show="teacherInter">
            <img src="@/assets/img/teacher7.png"
                 alt="">
          </div>
        </div>
        <div class="r">
          <div class="fr letter animated fadeInRight delay-2 "
               v-show="teacherInter">
            <img src="@/assets/img/teacher6.png"
                 alt="">
          </div>
          <div class="img fr animated fadeInRight delay-8 "
               v-show="teacherInter">
            <img src="@/assets/img/teacher5.png"
                 class="trans"
                 alt="">
          </div>
        </div>
      </div> -->

      </div>
      <div>
        <div class="strict rule-limit">
          <h1 class="tx-c model-title">师资严选</h1>
          <h4 class="tx-c">多层严选优秀师资，百里挑一严格淘汰</h4>
          <h4 class="tx-c">国内外钢琴大师赋能，助力钢琴老师稳步进阶</h4>
          <div class="flex flex-c">
            <img src="@/assets/img/teacher5.png"
                 alt="">
          </div>

        </div>

        <div class="honor rule-model-bg">
          <div class="flex flex-align flex-c rule-width">
            <img src="@/assets/img/teacher7.png"
                 alt="">
            <h1>荣誉墙</h1>
            <img src="@/assets/img/teacher8.png"
                 alt="">
          </div>
          <div class="content flex flex-c">
            <img class=""
                 src="@/assets/img/teacher6.png"
                 alt="">
          </div>

        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/index'
import bannerBg from '@/assets/img/know3.png'
import animated from '@/utils/animated.js'

import LoopImg from './components/loop.vue'

export default {
  components: {
    Layout,
    LoopImg,
  },
  data() {
    return {
      bannerBg: bannerBg,
      ladderNum: 2,

      activeTab: 1,
      teacherTop: false,
      teacherPlatform: false,
      teacherInter: false,
      refArray: [
        { ref: 'teacherTop', show: 'teacherTop' },
        { ref: 'teacherPlatform', show: 'teacherPlatform' },
        { ref: 'teacherInter', show: 'teacherInter' },
      ],
    }
  },
  mixins: [animated],
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.trans-box {
  background: #fff;
  transition: 1.6s ease;
}
.international {
  .r {
    width: 50%;
  }
  .l {
    width: 50%;
  }
  .letter {
    width: 100%;
  }
  .img {
    width: 87%;
    margin: 20px;
    border-radius: 40px;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .tab {
    margin: 30px 0 0;
    cursor: pointer;
    .bottom-wire {
      width: 100%;
      height: 4px;
      background: #ff903f;
      margin: 10px auto;
      -webkit-animation: load 0.5s ease-in;
    }
  }
  .one {
    img {
      width: 72%;
    }
  }
  .two {
    img {
      width: 65%;
    }
  }
}
.strict {
  img {
    width: 100%;
    margin: 50px 0;
    margin-left: -120px;
  }
}
.honor {
  h1 {
    margin: 0 20px;
  }
  .content {
    img {
      display: block;
      margin-left: 120px;
    }
    margin: 80px 0 20px;
  }
}
@-webkit-keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
