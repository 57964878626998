export default {
  data () {
    return {
      scroll: 0,
      scrollNum: 200,
      translateHeight: 1080,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    scroll: {
      handler (val) {
        if (val > this.scrollNum) {
          this.translateHeight = 370
        } else {
          this.translateHeight = 1080
        }
      },
    },
  },
  methods: {
    handleScroll () {
      let scr =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //  获取窗口滚动条高度
      this.scroll = scr

      let _this = this
      if (this.refArray) {
        this.refArray.forEach((r) => {
          _this.gdjz(r.ref, 50, () => {
            _this[r.show] = true
          })
        })
      }

    },
    gdjz (div, offset, callback) {
      let dom = this.$refs[div]
      if (dom) {
        var a, b, c, d
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = d + offset
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback()
        }
      }
    },
  },
}
